import React from 'react'

import BootstrapTable from 'react-bootstrap-table-next';

const products = [ {
    id: 1, name: 'pro1', price: 110
}, {
    id: 2, name: 'pro2', price: 120
}, {
    id: 3, name: 'pro3', price: 130
} ];
const columns = [{
  dataField: 'id',
  text: 'Product ID'
}, {
  dataField: 'name',
  text: 'Product Name'
}, {
  dataField: 'price',
  text: 'Product Price'
}];

function TestTable2() {
    return (
        <BootstrapTable keyField='id' data={ products } columns={ columns } />
    )
}

export default TestTable2